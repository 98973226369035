
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

import AtomCard from '@/components/atoms/AtomCard.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

import WizardErrorCodesEnum, { mapErrCodeToTranslation } from '@/enums/errorCodesEnum/WizardErrorCodesEnum';

interface Translations {
  title: string;
  earliestDepartureTitle: string;
  latestArrivalTitle: string;
  dateTitle: string;
  timeTitle: string;
  oclockTitle: string;
}

export default Vue.extend({
  name: 'OrganismSummaryTripDateCard',
  components: {
    AtomCard,
    AtomText,
    AtomSvgIcon,
  },
  data: () => ({
    mapErrCodeToTranslation,
    hasError: false,
    WizardErrorCodesEnum,
  }),
  props: {
    earliestDeparture: Object as PropType<moment.Moment>,
    latestDeparture: Object as PropType<moment.Moment>,
    translations: Object as PropType<Translations>,
    useAmPm: Boolean,
    action: Function,
  },
  computed: {
    ...mapGetters('rideIntent', ['getErrorCode']),
  },
  watch: {
    getErrorCode() {
      if (this.getErrorCode === '' || this.getErrorCode === WizardErrorCodesEnum.WALKING_DISTANCE) {
        this.hasError = false;
      } else { this.hasError = true; }
    },
  },
  methods: {
    displayTime(date: moment.Moment): string {
      const format = this.useAmPm ? 'hh:mm A' : 'HH:mm';
      return date.format(format);
    },
    displayDate(date: moment.Moment): string {
      const format = this.useAmPm ? 'Y.MM.DD' : 'DD.MM.Y';
      return date.format(format);
    },
    getErrorMessage() {
      if (this.getErrorCode === WizardErrorCodesEnum.CONFLICT) {
        return this.$t(mapErrCodeToTranslation[WizardErrorCodesEnum.TIME_SLOT_OVERLAP]).toString();
      }
      if (this.getErrorCode === WizardErrorCodesEnum.TOO_SMALL) {
        return this.$t(mapErrCodeToTranslation[WizardErrorCodesEnum.TIME_INTERVAL_TOO_SMALL]).toString();
      }
      return this.$t(mapErrCodeToTranslation[WizardErrorCodesEnum.GENERIC_MESSAGE]).toString();
    },
  },
});
