
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomCard',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeaderSlot(): boolean {
      return !!this.$slots.header;
    },
    hasFooterSlot(): boolean {
      return !!this.$slots.footer;
    },
  },
});
