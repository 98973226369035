
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CarTypeEnum from '@/enums/car/CarTypeEnum';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import GenderEnum from '@/enums/settings/GenderEnum';
import { StepperSteps } from '@/enums/stepper/StepperEnum';
import Switch from '@/enums/switch/SwitchEnum';
import {
  mapWeekDaysEnumToTranslations as days,
  mapWeekDaysOrder as daysOrder,
} from '@/enums/date/WeekDaysEnum';
import { useAmPm } from '@/common/dateCalculations';

import AtomCard from '@/components/atoms/AtomCard.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import MoleculeCard from '@/components/molecules/cards/MoleculeCard.vue';
import OrganismSummaryTripCard from '@/components/organisms/ride-intent/OrganismSummaryTripCard.vue';
import OrganismSummaryTripDateCard from '@/components/organisms/ride-intent/OrganismSummaryTripDateCard.vue';

import { sortMomentAsc } from '@/services/utilities/sort';

import { TripFrequency } from '@/store/modules/ride-intent/state';

import { hasFeature } from '@/services/feature/FeatureService';

export default Vue.extend({
  components: {
    AtomCard,
    AtomText,
    AtomActionText,
    AtomSvgIcon,
    MoleculeCard,
    OrganismSummaryTripCard,
    OrganismSummaryTripDateCard,
  },
  name: 'OrganismSummary',
  props: {},
  data: () => ({
    RolesEnum,
    Switch,
    StepperSteps,
    car: {},
    GenderEnum,
    FeatureEnum,
  }),
  methods: {
    ...mapActions('rideIntent', ['jumpToStep', 'toggleOutwardTripSteps']),
    useAmPm,
    hasFeature,
    isSelected(status: boolean) {
      return status ? this.$t('dictionary.yes').toString() : this.$t('dictionary.no').toString();
    },
    hasPaymentEnabled(role: RolesEnum): boolean {
      console.log('hasPaymentEnabled', this.getOtherDetails, role);
      if (role === RolesEnum.DRIVER) return this.getOtherDetails.claimsPayment;
      if (role === RolesEnum.PASSENGER) return this.getOtherDetails.offersPayment;

      return this.getOtherDetails.offersPayment && this.getOtherDetails.claimsPayment;
    },
    displayDate(date: moment.Moment): string {
      const format = useAmPm(this.$i18n.locale) ? 'Y.MM.DD' : 'DD.MM.Y';
      return date.format(format);
    },
  },
  computed: {
    ...mapGetters('rideIntent', [
      'getSelectedRole',
      'getStartPoint',
      'getEndPoint',
      'getWaypoints',
      'getReturnTripStatus',
      'getReturnStartPoint',
      'getReturnEndPoint',
      'getReturnWaypoints',
      'getEarliestDeparture',
      'getLatestDeparture',
      'getEarliestDeparture',
      'getLatestDeparture',
      'getEarliestInboundDeparture',
      'getLatestInboundDeparture',
      'getSelectedCar',
      'getOtherDetails',
      'getMeetingPoint',
      'getReturnMeetingPoint',
      'isEditMode',
      'isRecurrenceTrip',
      'isSequenceTrip',
      'isOutwardInThePast',
    ]),
    ...mapGetters('car', ['getCarById']),
    ...mapGetters('user', ['gender', 'b2cFree', 'features']),
    displayRole(): string {
      switch (this.getSelectedRole) {
        case RolesEnum.DRIVER:
          return this.$t('rideIntent.role.driver').toString();
        case RolesEnum.PASSENGER:
          return this.$t('rideIntent.role.passenger').toString();
        case RolesEnum.DRIVER_AND_PASSENGER:
          return this.$t('rideIntent.role.driverOrPassenger').toString();
        default:
          return '';
      }
    },
    inboundStatus(): string {
      return this.getReturnTripStatus === Switch.ON
        ? this.$t('rideIntent.outboundTrip.returnTripYes').toString()
        : this.$t('rideIntent.outboundTrip.returnTripNo').toString();
    },
    getOutboundTranslations(): object {
      return {
        title: this.$t('rideIntent.wizard.outbound').toString(),
        startTitle: this.$t('rideIntent.outboundTrip.startPoint').toString(),
        endTitle: this.$t('rideIntent.outboundTrip.endPoint').toString(),
        waypointTitle: this.$t('rideIntent.outboundTrip.stoppingPoint').toString(),
        meetingTitle: this.$t('rideIntent.outboundTrip.meetingPoint').toString(),
        returnTripTitle: this.$t('rideIntent.wizard.inbound').toString(),
      };
    },
    getInboundTranslations(): object {
      return {
        title: this.$t('rideIntent.wizard.inbound').toString(),
        startTitle: this.$t('rideIntent.outboundTrip.startPoint').toString(),
        endTitle: this.$t('rideIntent.outboundTrip.endPoint').toString(),
        waypointTitle: this.$t('rideIntent.outboundTrip.stoppingPoint').toString(),
        meetingTitle: this.$t('rideIntent.outboundTrip.meetingPoint').toString(),
        returnTripTitle: this.$t('rideIntent.wizard.inbound').toString(),
      };
    },
    getOutboundDateTranslations(): object {
      return {
        title: this.$t('rideIntent.wizard.outboundDateTime').toString(),
        earliestDepartureTitle: this.$t('rideIntent.summary.earliestDeparture').toString(),
        latestArrivalTitle: this.$t('rideIntent.summary.latestArrival').toString(),
        dateTitle: this.$t('dictionary.date').toString(),
        timeTitle: this.$t('dictionary.hour').toString(),
        oclockTitle: this.$t('dictionary.oclock').toString(),
      };
    },
    getInboundDateTranslations(): object {
      return {
        title: this.$t('rideIntent.wizard.inboundDateTime').toString(),
        earliestDepartureTitle: this.$t('rideIntent.summary.earliestDeparture').toString(),
        latestArrivalTitle: this.$t('rideIntent.summary.latestArrival').toString(),
        dateTitle: this.$t('dictionary.date').toString(),
        timeTitle: this.$t('dictionary.hour').toString(),
        oclockTitle: this.$t('dictionary.oclock').toString(),
      };
    },
    getOwnCarDetails() {
      const selectedCar = this.getSelectedCar;
      const car = selectedCar ? this.getCarById(selectedCar.id) : undefined;
      return car && selectedCar.type === CarTypeEnum.OWN_CAR
        ? {
          isOwnCar: true,
          hasCar: this.$t('dictionary.yes').toString(),
          plate: car.licensePlate,
          freeSeats: `
            ${selectedCar.freeSeats}
            ${this.$t(`rideIntent.carSelection.ownCar.${selectedCar.freeSeats === 1 ? 'availableSeatsSingular' : 'availableSeats'}`)}
          `,
        }
        : {
          isOwnCar: false,
          hasCar: this.$t('dictionary.no').toString(),
        };
    },
    getPoolCarDetails(): string {
      const selectedCar = this.getSelectedCar;
      return selectedCar && selectedCar.type === CarTypeEnum.POOL_CAR
        ? this.$t('dictionary.yes').toString()
        : this.$t('dictionary.no').toString();
    },
    getRentalCarDetails(): object {
      const rentalCar = this.getSelectedCar;
      return rentalCar && rentalCar.type === CarTypeEnum.RENTAL_CAR
        ? {
          isRental: true,
          hasCar: this.$t('dictionary.yes').toString(),
          plate: rentalCar.plating,
          color: rentalCar.color,
          model: rentalCar.model,
          freeSeats: `${rentalCar.freeSeats} ${rentalCar.freeSeats === 1
            ? this.$t('rideIntent.carSelection.ownCar.availableSeatsSingular')
            : this.$t('rideIntent.carSelection.ownCar.availableSeats')
          }`,
        }
        : {
          isRental: false,
          hasCar: this.$t('dictionary.no').toString(),
        };
    },
    rideRepeatsOnSpecificDays(): boolean {
      const { frequency } = this.getOtherDetails.repeat;

      return frequency === TripFrequency.SPECIFIC_DAYS;
    },
    getRideRepeat(): string {
      const { on, frequency } = this.getOtherDetails.repeat;
      if (frequency === TripFrequency.NEVER) return this.$t('dictionary.never').toString();
      if (frequency === TripFrequency.SPECIFIC_DAYS) {
        const dates = [...on] as moment.Moment[];
        return dates
          .sort(sortMomentAsc)
          .map((date) => date.format('DD.MM.Y'))
          .join(', ');
      }
      if (frequency === TripFrequency.RECURRENT) {
        const selectedDays = [...on] as string[];
        const weekDays = selectedDays
          .sort((keyA, keyB) => daysOrder[keyA] - daysOrder[keyB])
          .map((key) => this.$t(days[key]).toString())
          .join(', ');

        return `${this.$t('rideIntent.summary.recurrent')} ${weekDays}`;
      }

      return '';
    },
  },
});
