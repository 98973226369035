
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

import Switch from '@/enums/switch/SwitchEnum';
import WizardErrorCodesEnum, { mapErrCodeToTranslation } from '@/enums/errorCodesEnum/WizardErrorCodesEnum';

import { formatAddress, getSequence } from '@/common/GeoFormatters';

import AtomCard from '@/components/atoms/AtomCard.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

import { Location } from '@/store/modules/ride-intent/state';

interface Translations {
  title: string;
  startTitle: string;
  endTitle: string;
  waypointTitle: string;
  meetingTitle?: string;
  returnTripTitle?: string;
}

export default Vue.extend({
  name: 'OrganismSummaryTripCard',
  components: {
    AtomCard,
    AtomText,
    AtomSvgIcon,
  },
  data: () => ({
    mapErrCodeToTranslation,
    hasError: false,
    WizardErrorCodesEnum,
  }),
  props: {
    startLocation: Object as PropType<Location>,
    endLocation: Object as PropType<Location>,
    waypoints: Array as PropType<Location[]>,
    translations: Object as PropType<Translations>,
    meetingPoint: {
      type: String,
      required: false,
      default: '',
    },
    returnTripStatus: {
      type: String as PropType<Switch>,
      required: false,
      default: '',
    },
    action: Function,
  },
  watch: {
    getErrorCode() {
      if (this.getErrorCode === WizardErrorCodesEnum.WALKING_DISTANCE) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    },
  },
  computed: {
    ...mapGetters('rideIntent', ['getErrorCode']),
  },
  methods: {
    displayAddress(location: Location): string {
      const { location: geolocation } = location;
      if (geolocation) {
        return formatAddress(geolocation);
      }
      return '';
    },
    getSequence,
    getErrorMessage() {
      if (this.getErrorCode === WizardErrorCodesEnum.WALKING_DISTANCE) {
        return this.$t(mapErrCodeToTranslation[WizardErrorCodesEnum.DISTANCE_TOO_SMALL]).toString();
      }
      return this.$t(mapErrCodeToTranslation[WizardErrorCodesEnum.GENERIC_MESSAGE]).toString();
    },
  },
});
